<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadooperarios') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <operarios-estados :activos="activos" :eliminados="eliminados" />
                </div>
                <div class="col-md-10">
                    <div class="row">
                        <Dropdown scrollHeight="600px" v-if="mostrareliminados == false" showClear
                            v-model="especialidadselec" :options="especialidades" @change="obtenerDatos($event)" filter
                            optionLabel="nombre" placeholder="Filtro especialidad">
                        </Dropdown>
                    </div>
                    <p></p>
                    <tabla-datos v-if="mostrareliminados==false" :columnas="columnas" :datos="operarios"
                        :filtros="filtros" :filtrosBuscador="filtrosBuscador" :total="total"
                        @quieroDatos="obtenerDatos"></tabla-datos>
                    <tabla-datos v-if="mostrareliminados==true" :columnas="columnasdos" :datos="operarioseliminados"
                        :filtros="filtrosdos" :filtrosBuscador="filtrosBuscadordos" :total="eliminados"
                        @quieroDatos="obtenereliminados"></tabla-datos>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import OperariosEstados from './listado/OperariosEstados.vue'
import TablaDatos from '../../TablaDatos.vue';
import { FilterMatchMode } from 'primevue/api';
import Dropdown from 'primevue/dropdown';

export default {
    components: {
        'operarios-estados': OperariosEstados,
        'tabla-datos': TablaDatos,
        Dropdown
    },
    data() {
        return {
            operarios: [],
            activos: '',
            operarioseliminados:[],
            eliminados: '',
            especialidades:[],
            especialidadselec:'',
            mostrareliminados: false,
            parametros: null, // query params de DataTable
            parametrosdos: null, // query params de DataTable
            filtrosBuscador: ['nombre'], // filtros para el buscador
            filtrosBuscadordos: ['nombre'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },

            },
            filtrosdos: { // filtros de columna
                'nombre': { value: "", matchMode: FilterMatchMode.CONTAINS },

            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },

                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idoperario',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar operario ' + id);
                                this.$router.push({
                                    name: 'Operario',
                                    params: {
                                        id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminaroperario',
                            tipo: 'danger',
                            nombreId: 'idoperario',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar cia ' + id);
                                if (confirm('¿Desea eliminar al operario?')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ],
            columnasdos: [
                {
                    header: 'Nombre',
                    field: 'nombre',
                    sortable: true,
                },

                {
                    header: 'Acciones',
                    data: null,
                    sortable:false,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'idoperario',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar operario ' + id);
                                this.$router.push({
                                    name: 'Operario',
                                    params: {
                                        id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Recuperar',
                            class: 'recuperaroperario',
                            tipo: 'success',
                            nombreId: 'idoperario',
                            icono: 'fa fa-recycle',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                if (confirm('¿Desea recuperar al operario?')) {
                                    this.recuperar(id);
                                }
                            }
                        },
                    ]
                }
            ],
        }
    },
     methods: {
        async obtenerDatos(parametros) {
            /* get  modulos/pwgsapi/index.php/operarios/simple */
             const api = new PwgsApi;
             let params = parametros;
             if (this.especialidadselec) {
                 const datos = await api.get('operarios/especialidad,' + this.especialidadselec.idespecialidad, params);
                 this.operarios = datos.datos;
                 this.total = datos.n_total_registros;


             }else{
                 const datos = await api.get('operarios', params);
                 this.operarios = datos.datos;
                 this.total = datos.n_total_registros;


             }
             this.activos = this.total;
             this.columnas[0].header = this.$t("general.nombre");
             this.columnas[1].header = this.$t("general.acciones");
             this.mostrareliminados = false;
         },
         async obtenerEspecialidades() {
             //GET /pwgsapi/index.php/especialidades
             const api = new PwgsApi();
             let aux = await api.get('especialidades');
             this.especialidades = aux.datos;
        },
        async eliminar(id) {
            /*delete  modulos/pwgsapi/index.php/operarios/:id */
            const api = new PwgsApi;
            await api.delete('operarios/' + id);
            this.obtenerDatos();
         },
         async obtenereliminados(parametrosdos) {
             let params = parametrosdos;
             const api = new PwgsApi();
             const datos = await api.get('operarios/eliminados',params);
             this.eliminados = datos.n_total_registros;
             this.operarioseliminados = datos.datos;
         },
         cambiaractivos() {
            this.mostrareliminados = false;
         },
         cambiareliminados() {
            this.mostrareliminados = true;
         },
         async recuperar(id) {
            //PUT  modulos/pwgsapi/index.php/operarios/:id
            const api = new PwgsApi();
            let subidadatos = { estado_operario: "" };
            await api.put('operarios/' + id, subidadatos);
            this.obtenerDatos();
            this.cambiaractivos();
        }
    },
    mounted() {
        this.obtenerDatos();
        this.obtenereliminados();
        this.obtenerEspecialidades();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
.recuperaroperario {}
.eliminaroperario {}
.oculta {
    display: none !important;
}
.p-dropdown-panel{
    border: 1px solid;
    color: #c1c1c1;
}
</style>